/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/theme/globals.css';

import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;
